import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  CalendarDaysIcon,
  HomeIcon,
  ShoppingBagIcon,
  UserCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Link from "next/link";
import { useRouter } from "next/router";
import TermsOfUseModal from "@/components/Common/Customer/TermsOfUse";
import useModal from "@/hooks/useModal";
import Image from "next/image";
import { twMerge } from "tailwind-merge";

export default function CustomerHeader() {
  const router = useRouter();

  const [, setShowTermsOfUseModal] = useModal("terms-of-use");

  const navigation = [
    {
      name: "ホーム",
      href: "/home",
      current: router.asPath.startsWith("/home"),
      icon: <HomeIcon className="h-6 w-6" aria-hidden="true" />,
    },
    {
      name: "アイテム",
      href: "/item",
      current: router.asPath.startsWith("/item"),
      icon: <ShoppingBagIcon className="h-6 w-6" aria-hidden="true" />,
    },
    {
      name: "予約",
      href: "/reserve",
      current: router.asPath.startsWith("/reserve"),
      icon: <CalendarDaysIcon className="h-6 w-6" aria-hidden="true" />,
    },
    {
      name: "会員情報",
      href: "/mypage",
      current: router.asPath.startsWith("/mypage"),
      icon: <UserCircleIcon className="h-6 w-6" aria-hidden="true" />,
    },
  ];

  return (
    <>
      <TermsOfUseModal />
      <Disclosure as="nav" className="sticky left-0 top-0 z-40 border-b-2 border-gray-100 bg-white">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-primary hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">メニューを開く</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex flex-shrink-0 items-center">
                    <Link href={"/"}>
                      <Image
                        src="/assets/svg/common/ktg-logo.svg"
                        alt="かたぎり塾"
                        width={100}
                        height={100}
                        className="h-8 w-auto"
                      />
                    </Link>
                  </div>
                  <div className="hidden sm:ml-6 sm:block">
                    <div className="flex space-x-4">
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          href={item.href}
                          className={twMerge(
                            item.current
                              ? "bg-white font-bold text-primary"
                              : "font-medium text-gray-800 hover:bg-primary hover:text-white",
                            "rounded-md px-3 py-2 text-sm"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  {/* <button
                    type="button"
                    className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span className="sr-only">通知を見る</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button> */}

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
                        <span className="sr-only">ユーザーメニューを開く</span>
                        <svg className="h-6 w-6 text-gray-500" fill="currentColor" viewBox="0 4 24 24">
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-primary ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              href="/mypage"
                              className={twMerge(active ? "bg-orange-50" : "", "block px-4 py-2 text-sm text-gray-700")}
                            >
                              会員情報
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              href="#"
                              onClick={(e) => {
                                setShowTermsOfUseModal(true);
                                e.preventDefault();
                              }}
                              className={twMerge(active ? "bg-orange-50" : "", "block px-4 py-2 text-sm text-gray-700")}
                            >
                              利用規約
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              href="/logout"
                              className={twMerge(active ? "bg-orange-50" : "", "block px-4 py-2 text-sm text-gray-700")}
                            >
                              ログアウト
                            </Link>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {navigation.map((item) => (
                  <Link key={item.name} href={item.href}>
                    <Disclosure.Button
                      className={twMerge(
                        item.current
                          ? "font-bold text-primary"
                          : "font-medium text-gray-900 hover:bg-primary hover:text-white",
                        "block rounded-md px-3 py-2 text-base"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  </Link>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <div className="fixed inset-x-0 bottom-0 z-50 h-16 border-t-2 border-t-gray-100 bg-white py-2 shadow-lg sm:hidden">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-around">
            {navigation.map((item) => (
              <Link
                key={item.name}
                href={item.href}
                className={twMerge(
                  item.current ? "font-bold text-primary" : "text-gray-400",
                  "flex flex-col items-center justify-center text-xs"
                )}
              >
                <div className="flex flex-col items-center">
                  {item.icon}
                  <p className="pt-1 text-xs">{item.name}</p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
