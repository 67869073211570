import { AlertsContext } from "@/providers/UI/Common/AlertsProvider";
import Link from "next/link";
import { useRouter } from "next/router";
import { useContext, useEffect } from "react";

const statusMap: {
  [key: string]: {
    [key: string]: {
      title: string;
      message: string | React.ReactNode;
      status: "success" | "error" | "info" | "warning";
    };
  };
} = {
  "stripe-setup": {
    success: {
      title: "決済設定が完了しました",
      message: (
        <>
          アイテムを購入するには、<Link href="/item">アイテムページ</Link>をご覧ください。
        </>
      ),
      status: "success",
    },
    cancel: {
      title: "決済設定をキャンセルしました",
      message: "もう一度設定するには、Stripe決済設定リンクをご利用ください。",
      status: "warning",
    },
  },
  "stripe-payment": {
    success: {
      title: "決済が完了しました",
      message: (
        <>
          決済履歴を確認するには、<Link href="/mypage/payment-history">決済履歴ページ</Link>をご覧ください。
        </>
      ),
      status: "success",
    },
    cancel: {
      title: "決済をキャンセルしました",
      message: "もう一度決済するには、再度アイテムを選択してください。",
      status: "warning",
    },
  },
  "auth0-logout": {
    success: {
      title: "ログアウトしました",
      message: "ログイン画面に戻ります。",
      status: "success",
    },
  },
};

export default function QueryStatusHandler() {
  const router = useRouter();
  const { showAlert } = useContext(AlertsContext);

  useEffect(() => {
    if (router.isReady) {
      const query = router.query;
      if (query.status) {
        const key = (query.status as string).split(":")[0];
        if (key in statusMap) {
          const alertCategory = statusMap[key];
          const alertStatus = (query.status as string).split(":")[1];
          if (alertStatus in alertCategory) {
            const { title, message, status } = statusMap[key][alertStatus];
            showAlert(title, message, status);
            delete query.status;
            router.replace(router.pathname, undefined, { shallow: true });
            return;
          }
        }
        showAlert("エラーが発生しました", "不明なエラーが発生しました", "error");
      }
    }
  }, [router, router.isReady, router.query, showAlert]);

  return null;
}
