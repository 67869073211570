import { Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { createContext, Fragment, useState } from "react";

type ContextType = {
  showAlert: (
    title: string,
    message: string | React.ReactNode,
    type: "success" | "error" | "info" | "warning",
    duration?: number
  ) => void;
  resetAlert: () => void;
};

export const AlertsContext = createContext<ContextType>({} as ContextType);

export default function AlertsProvider(props: { children: React.ReactNode }) {
  const children = props.children;

  const [alert, setAlert] = useState<{
    title: string;
    message: string | React.ReactNode;
    type: "success" | "error" | "info" | "warning";
    duration: number;
  } | null>(null);

  const showAlert = (
    title: string,
    message: string | React.ReactNode,
    type: "success" | "error" | "info" | "warning",
    duration = 5000
  ) => {
    setAlert({ title, message, type, duration });

    if (timer) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        resetAlert();
      }, duration)
    );
  };

  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  function resetAlert() {
    setAlert(null);
  }

  return (
    <AlertsContext.Provider value={{ showAlert, resetAlert }}>
      {children}
      {alert && (
        <div
          aria-live="assertive"
          className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
          style={{ zIndex: 9999 }}
        >
          <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
            <Transition
              show={alert !== null}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      {
                        {
                          success: <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />,
                          error: <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />,
                          info: <InformationCircleIcon className="h-6 w-6 text-orange-400" aria-hidden="true" />,
                          warning: <ExclamationCircleIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />,
                        }[alert.type]
                      }
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">{alert.title}</p>
                      <p className="mt-1 text-sm text-gray-500">
                        {typeof alert.message === "string" ? alert.message?.replace("VALIDATION ", "") : alert.message}
                      </p>
                    </div>
                    <div className="ml-4 flex flex-shrink-0">
                      <button
                        type="button"
                        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                        onClick={() => setAlert(null)}
                      >
                        <span className="sr-only">閉じる</span>
                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      )}
    </AlertsContext.Provider>
  );
}
