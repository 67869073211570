import useKtgAuth0 from "@/hooks/auth/useKtgAuth0";
import { Navigation } from "@/types/type";
import { VariableIcon } from "@heroicons/react/24/outline";
import { useRouter } from "next/router";

export default function useTestSidebar(): Navigation[] {
  const router = useRouter();
  const { user } = useKtgAuth0();

  const email = user?.email;

  // tatsuo.yamamoto or yuki.onoda

  if (email?.startsWith("tatsuo.yamamoto") || email?.startsWith("yuki.onoda")) {
    return [
      {
        name: "テスト",
        href: "/admin/test/caname-core-v1",
        icon: VariableIcon,
        current: router.asPath.startsWith("/admin/test/caname-core-v1"),
      },
    ];
  } else {
    return [];
  }
}
