import { AlertsContext } from "@/providers/UI/Common/AlertsProvider";
import { useContext } from "react";

export default function useShowAlert() {
  const { showAlert } = useContext(AlertsContext);

  return {
    showAlert,
    showSuccess: (title: string, message?: string) => {
      showAlert(title, message, "success");
    },
    showError: (title: string, message?: string) => {
      showAlert(title, message, "error");
    },
    onError: (error: Error) => {
      console.error(error);
      showAlert("エラーが発生しました", error.message, "error");
    },
  };
}
