import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, split } from "@apollo/client";
import usePromise from "@/hooks/General/usePromise";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { createContext, useMemo, useState } from "react";
import SpinnerLarge from "@/components/General/Spinner/Large";
import { getMainDefinition } from "@apollo/client/utilities";
import useKtgAuth0 from "@/hooks/auth/useKtgAuth0";

type ContextType = {
  auth: {
    bearerToken?: string;
  };
};

export const AuthProviderContext = createContext<ContextType>({} as ContextType);

export default function CustomApolloProvider({ children }: { children: JSX.Element }) {
  const { getAccessTokenSilently, loginWithRedirect, isAuthenticated } = useKtgAuth0();

  const getToken = new Promise<string>(async (resolve, reject) => {
    if (isAuthenticated) {
      try {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: "https://hasura.io/learn",
            scope: "openid profile email offline_access",
          },
        });
        resolve(token);
      } catch (error) {
        reject(error);
      }
    }
    resolve("");
  });

  const onError = () => {
    loginWithRedirect({
      authorizationParams: {
        audience: "https://hasura.io/learn",
        scope: "openid profile email offline_access",
      },
    });
  };

  const bearerToken = usePromise(getToken, onError);

  const wslink = useMemo(() => {
    if (typeof window !== "undefined" && bearerToken) {
      return new GraphQLWsLink(
        createClient({
          url: process.env.NEXT_PUBLIC_GRAPHQL_URI?.replace("https", "wss") ?? "",
          connectionParams: () => ({
            headers: {
              authorization: `Bearer ${bearerToken}`,
            },
          }),
        })
      );
    }
    return null;
  }, [bearerToken]);

  const [httplink] = useState(new HttpLink({ uri: process.env.NEXT_PUBLIC_GRAPHQL_URI }));

  const link = useMemo(() => {
    // isAuthenticated: Auth0のアカウントがなかったらゲストアカウントなので，HttpLinkを返す
    // typeof window !== "undefined": SSR時には，HttpLinkを返す
    if (!isAuthenticated || typeof window === "undefined") {
      return httplink;
    }
    // wslinkがなかったら，まだLinkをつくらない
    if (!wslink) {
      return null;
    }
    return split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === "OperationDefinition" && definition.operation === "subscription";
      },
      wslink,
      httplink
    );
  }, [wslink, httplink, isAuthenticated]);

  const client = useMemo(() => {
    const authedHeader = setContext(async () => {
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          accept: "application/json",
          "content-type": "application/json",
          "cache-control": "must-revalidate",
          authorization: `Bearer ${bearerToken}`,
        },
      };
    });

    const client = new ApolloClient({
      ssrMode: typeof window === "undefined",
      link: link ? authedHeader.concat(link) : authedHeader,
      cache: new InMemoryCache(),
    });
    return client;
  }, [bearerToken, link]);

  return (
    <AuthProviderContext.Provider
      value={{
        auth: {
          bearerToken: bearerToken ?? undefined,
        },
      }}
    >
      <ApolloProvider client={client}>{isAuthenticated && !bearerToken ? <SpinnerLarge /> : children}</ApolloProvider>
    </AuthProviderContext.Provider>
  );
}
