import { ReactNode, useContext, useEffect } from "react";
import CustomerHeader from "./Header";
import { useRouter } from "next/router";
import { PayAdmissionFeeDocument, PayAdmissionFeeMutation } from "@/graphql/generated/graphql";
import { CustomerBasicInfoContext } from "@/providers/data/Common/CustomerBasicInfoProvider";
import SpinnerLarge from "@/components/General/Spinner/Large";
import { useMutation } from "@apollo/client";

export default function CustomerLayout({ children }: { children: ReactNode }) {
  const router = useRouter();

  const { customerBasicInfo, refetchCustomerBasicInfo } = useContext(CustomerBasicInfoContext);

  // const [ payAdmissionFee ] = usePayAdmissionFeeMutation();
  const [payAdmissionFee] = useMutation<PayAdmissionFeeMutation>(PayAdmissionFeeDocument);

  useEffect(() => {
    if (router.isReady) {
      if (customerBasicInfo.shopId === null) {
        if (router.pathname !== "/mypage/edit") {
          router.push("/mypage/edit");
        }
        return;
      }
      if (customerBasicInfo.admittedAt === null) {
        payAdmissionFee()
          .then(({ data }) => {
            if (!data) {
              router.push("/500");
            } else {
              if (data.generateStripeAdmissionSession.url === "") {
                refetchCustomerBasicInfo();
              } else if (data.generateStripeAdmissionSession.url) {
                window.location.href = data.generateStripeAdmissionSession.url;
              } else {
                router.push("/500");
              }
            }
          })
          .catch(() => {
            router.push("/500");
          });
        return;
      }
      if (customerBasicInfo.plan === null && router.pathname !== "/mypage") {
        router.push("/mypage");
        return;
      }
    }
  }, [customerBasicInfo, payAdmissionFee, router, refetchCustomerBasicInfo]);
  return (
    <>
      <CustomerHeader />
      <main className="pb-16 sm:pb-4">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          {!router.isReady || (customerBasicInfo.shopId && !customerBasicInfo.admittedAt) ? <SpinnerLarge /> : children}
        </div>
      </main>
    </>
  );
}
