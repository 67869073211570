import { atomFamily } from "recoil";

export type ModalType =
  | "terms-of-use"
  | "consent"
  | "owner-update-user-shop"
  | "reserve"
  | "change-plan"
  | "reserve-repeat";

export const ModalOpenState = atomFamily<boolean, ModalType>({
  key: "ModalOpenState",
  default: false,
});

export type ReserveDetailModalType = "reserve-detail" | "reserve-cancel";

export const ReserveDetailModalState = atomFamily<number | null, ReserveDetailModalType>({
  key: "ReserveDetailModalState",
  default: null,
});
