import { GetIsOwnerDocument } from "@/graphql/generated/graphql";
import { useQuery } from "@apollo/client";
import useKtgAuth0 from "../auth/useKtgAuth0";

/**
 * don't use. use `useContext(AdminGlobalVarContext).user.role;` instead of this.
 * @returns "hq" | "customer" | "owner" | "trainer" | undefined
 */
export function useGetRole() {
  const { user } = useKtgAuth0();
  const rawRole = user?.["https://hasura.io/jwt/claims"].role;
  const trainerId = user?.["https://hasura.io/jwt/claims"].trainerId;
  const { data } = useQuery(GetIsOwnerDocument, { variables: { id: trainerId } });

  const isHq = rawRole === "hq";
  const isCustomer = rawRole === "customer";
  const isTrainer = rawRole === "trainer";
  const isOwner = data?.trainersByPk?.owner;
  const role = isHq ? "hq" : isCustomer ? "customer" : isOwner ? "owner" : isTrainer ? "trainer" : undefined;
  return role;
}
