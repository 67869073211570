import SpinnerLarge from "@/components/General/Spinner/Large";
import { GetBasicCustomerInfoDocument, GetBasicCustomerInfoQuery } from "@/graphql/generated/graphql";
import useShowAlert from "@/hooks/useHandleError";
import { dayjsTz } from "@/lib/dayjs";
import { useQuery } from "@apollo/client";
import { useRouter } from "next/router";
import { createContext, useState } from "react";

type ContextType = {
  customerBasicInfo: CustomerBasicInfo;
  refetchCustomerBasicInfo: () => void;
};

export type CustomerBasicInfo = GetBasicCustomerInfoQuery["customers"][0] & {
  computed: {
    rsvs: {
      future: GetBasicCustomerInfoQuery["customers"][0]["rsvs"];
      past: GetBasicCustomerInfoQuery["customers"][0]["rsvs"];
      map: { [key: string]: number | undefined };
    };
  };
};

export const CustomerBasicInfoContext = createContext<ContextType>({} as ContextType);

export default function CustomerBasicInfoProvider(props: { children: React.ReactNode }) {
  const children = props.children;

  const { onError } = useShowAlert();
  const router = useRouter();

  const [currentTime, setCurrentTime] = useState<string>(dayjsTz().format());
  const [cachedCustomerBasicInfo, setCachedCustomerBasicInfo] = useState<CustomerBasicInfo | null>(null);
  const { refetch } = useQuery<GetBasicCustomerInfoQuery>(GetBasicCustomerInfoDocument, {
    variables: {
      now: currentTime,
    },
    onCompleted: (data) => {
      if (data?.customers?.length) {
        const customer = data.customers[0];
        setCachedCustomerBasicInfo({
          ...customer,
          computed: {
            rsvs: {
              future: customer.rsvs
                .filter((rsv) => dayjsTz(rsv.beginAt).isAfter(currentTime))
                .sort((a, b) => (dayjsTz(a.beginAt).isAfter(dayjsTz(b.beginAt)) ? 1 : -1)),
              past: customer.rsvs
                .filter((rsv) => dayjsTz(rsv.beginAt).isBefore(currentTime))
                .sort((a, b) => (dayjsTz(a.beginAt).isAfter(dayjsTz(b.beginAt)) ? -1 : 1)),
              map: customer.rsvs.reduce(
                (acc, rsv) => {
                  acc[dayjsTz(rsv.beginAt).format("YYYY-MM-DD HH")] = rsv.id;
                  return acc;
                },
                {} as { [key: string]: number }
              ),
            },
          },
        });
      }
    },
    onError: (error) => {
      onError(error);
      router.push("/logout");
    },
  });

  function refetchCustomerBasicInfo() {
    setCurrentTime(dayjsTz().format());
    refetch();
  }

  return !cachedCustomerBasicInfo ? (
    <>
      <SpinnerLarge />
    </>
  ) : (
    <CustomerBasicInfoContext.Provider value={{ customerBasicInfo: cachedCustomerBasicInfo, refetchCustomerBasicInfo }}>
      {children}
    </CustomerBasicInfoContext.Provider>
  );
}
