import { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { DocumentCheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Markdown from "markdown-to-jsx";
import useModal from "@/hooks/useModal";
import SpinnerLarge from "../../General/Spinner/Large";

export default function TermsOfUseModal() {
  const [open, setOpen] = useModal("terms-of-use");
  const [termsOfUseMd, setTermsOfUseMd] = useState<string | null>(null);
  const [title, setTitle] = useState<string | null>(null);

  const scrollTopRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (open) {
      scrollTopRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [termsOfUseMd, open]);

  useEffect(() => {
    fetch("/assets/documents/terms-of-use.md")
      .then((r) => r.text())
      .then((text) => {
        setTitle(text.split("\n")[0].replace("# ", "").trim());
        setTermsOfUseMd(text.replace(text.split("\n")[0], "").trim());
      });
  }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                {!title ? (
                  <SpinnerLarge />
                ) : (
                  <>
                    <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">閉じる</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-100 sm:mx-0 sm:h-10 sm:w-10">
                        <DocumentCheckIcon className="h-6 w-6 text-orange-600" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-left sm:ml-4 sm:mt-0">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          {title}
                        </Dialog.Title>
                        <div className="mt-2">
                          {termsOfUseMd && (
                            <article className="lg:prose-md prose">
                              <Markdown>{termsOfUseMd}</Markdown>
                            </article>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => setOpen(false)}
                      >
                        閉じる
                      </button>
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
