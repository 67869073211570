import { ReactNode, useEffect, useState } from "react";
import GuestLayout from "./Guest";
import Head from "next/head";
import CustomerLayout from "@/components/Layout/Customer";
import { useRouter } from "next/router";
import AlertsProvider from "@/providers/UI/Common/AlertsProvider";
import QueryStatusHandler from "../Common/QueryStatusHandler";
import CustomerBasicInfoProvider from "@/providers/data/Common/CustomerBasicInfoProvider";
import AdminLayout from "./Admin";
import AdminGlobalVarProvider from "@/providers/data/Common/Admin/GlobalVarProvider";
import SpinnerLarge from "../General/Spinner/Large";
import useKtgAuth0 from "@/hooks/auth/useKtgAuth0";

const loginRequiredUrlPrefixes = ["/home", "/item", "/reserve", "/mypage", "/admin"];

export default function Layout({ children }: { children: ReactNode }) {
  const { isAuthenticated, isLoading, user } = useKtgAuth0();
  const router = useRouter();

  const [checkPassed, setCheckPassed] = useState(false);

  useEffect(() => {
    if (router.isReady && !isLoading) {
      if (loginRequiredUrlPrefixes.some((prefix) => router.pathname.startsWith(prefix))) {
        if (!isAuthenticated) {
          router.push("/welcome");
          return;
        }
        if (user && user["https://hasura.io/jwt/claims"].role === "customer" && router.pathname.startsWith("/admin")) {
          router.push("/");
          return;
        }
        if (user && user["https://hasura.io/jwt/claims"].role !== "customer" && !router.pathname.startsWith("/admin")) {
          router.push("/admin");
          return;
        }
      }
      setCheckPassed(true);
    }
  }, [isAuthenticated, isLoading, router, user]);

  return (
    <>
      <Head>
        <title>かたぎり塾 WebApp</title>
        <meta name="description" content="caname inc" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/assets/svg/common/ktg-logo.svg" />
        <link rel="manifest" href="/manifest.json" />
      </Head>
      <AlertsProvider>
        {!router.isReady || isLoading || !checkPassed ? (
          <SpinnerLarge />
        ) : router.pathname !== "/logout" && isAuthenticated && user ? (
          user["https://hasura.io/jwt/claims"].role === "customer" ? (
            <CustomerBasicInfoProvider>
              <CustomerLayout>{children}</CustomerLayout>
            </CustomerBasicInfoProvider>
          ) : (
            <AdminGlobalVarProvider>
              <AdminLayout>{children}</AdminLayout>
            </AdminGlobalVarProvider>
          )
        ) : (
          <GuestLayout>{children}</GuestLayout>
        )}
        <QueryStatusHandler />
      </AlertsProvider>
    </>
  );
}
