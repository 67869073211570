import getCustomerIdFromPath from "@/lib/paths/getCustomerIdFromPath";
import getShopIdFromPath from "@/lib/paths/getShopIdFromPath";
import { AdminGlobalVarContext } from "@/providers/data/Common/Admin/GlobalVarProvider";
import { Navigation } from "@/types/type";
import {
  HomeIcon,
  BuildingStorefrontIcon,
  UserGroupIcon,
  CalendarDaysIcon,
  CreditCardIcon,
  CurrencyYenIcon,
  ChartBarIcon,
  UserPlusIcon,
  ArrowPathIcon,
  UsersIcon,
  UserMinusIcon,
  InformationCircleIcon,
  DocumentCheckIcon,
  UserIcon,
  ChatBubbleLeftRightIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";
import { useRouter } from "next/router";
import { useContext } from "react";

export default function usePrimarySidebar(): Navigation[] {
  const { shops } = useContext(AdminGlobalVarContext);
  const router = useRouter();

  const shopId = getShopIdFromPath(router.asPath);
  const shopName = shops.find((shop) => shop.id === Number(shopId))?.name;
  const customerId = getCustomerIdFromPath(router.asPath);

  const homeNavigation = getHomeNavigation(router.asPath, shops);
  const shopRootNavigation = getShopRootNavigation(router.asPath, shops);
  const shopNavigation = getShopNavigation(router.asPath, shopId, shopName, customerId, shops);

  const primaryNavigation: Navigation[] = [...homeNavigation, ...shopRootNavigation, ...shopNavigation];

  return primaryNavigation;
}

function getHomeNavigation(path: string, shops: { id: number; name: string }[]): Navigation[] {
  if (shops.length <= 1) {
    return [];
  }
  return [
    {
      name: "ホーム",
      href: "/admin/home",
      current: path.startsWith("/admin/home"),
      icon: HomeIcon,
    },
  ];
}

function getShopRootNavigation(path: string, shops: { id: number; name: string }[]): Navigation[] {
  const selectedShopId = getShopIdFromPath(path);

  if (shops.length <= 1) {
    return [];
  }

  return [
    {
      name: shops.length === 1 ? shops[0].name : "全店舗",
      href: shops.length === 1 ? `/admin/shops/${shops[0].id}` : "/admin/shops",
      current:
        shops.length === 1 ? path.startsWith("/admin/shops?") : path.startsWith("/admin/shops") && !selectedShopId,
      icon: BuildingStorefrontIcon,
    },
  ];
}

function getShopNavigation(
  path: string,
  shopId: string | undefined,
  shopName: string | undefined,
  customerId: string | undefined,
  shops: { id: number; name: string }[]
): Navigation[] {
  // 店舗が１店舗なら隠さない
  if ((!shopId || !shopName) && shops.length !== 1) {
    return [];
  }

  const correctedShopId = shops.length === 1 ? String(shops[0].id) : shopId;
  const correctedShopName = shops.length === 1 ? shops[0].name : shopName;

  const indentLevel = shops.length <= 1 ? 0 : 1;
  // 所属店舗が１店舗ならPrimaryに出す
  const customerDetail =
    shops.length === 1 && customerId
      ? [
          {
            name: "塾生詳細",
            href: `/admin/customers/${customerId}`,
            current: path === `/admin/customers/${customerId}` || path.startsWith(`/admin/customers/${customerId}?`),
            icon: UserIcon,
            indentLevel: 1,
          },
          {
            name: "トレーニングレコード",
            href: `/admin/customers/${customerId}/training-record`,
            current:
              path === `/admin/customers/${customerId}/training-record` ||
              path.startsWith(`/admin/customers/${customerId}/training-record?`),
            icon: UserGroupIcon,
            indentLevel: 1,
            // only: ["hq", "owner", "trainer],
          },
          {
            name: "体組成記録",
            href: `/admin/customers/${customerId}/training-record/body-composition`,
            current: path.startsWith(`/admin/customers/${customerId}/training-record/body-composition`),
            icon: CalendarDaysIcon,
            indentLevel: 1,
            // only: ["hq"],
          },
          {
            name: "電子カルテ",
            href: `/admin/customers/${customerId}/training-record/counseling-sheet`,
            current: path.startsWith(`/admin/customers/${customerId}/training-record/counseling-sheet`),
            icon: DocumentTextIcon,
            indentLevel: 1,
            // only: ["hq"],
          },
        ]
      : [];

  const homeAndCustomer = [
    {
      name: `${correctedShopName}のホーム`,
      href: `/admin/shops/${correctedShopId}`,
      current: path === `/admin/shops/${correctedShopId}` || path.startsWith(`/admin/shops/${correctedShopId}?`),
      icon: HomeIcon,
      indentLevel: indentLevel,
    },
    {
      name: "塾生",
      href: `/admin/shops/${correctedShopId}/customers`,
      current: path === `/admin/shops/${correctedShopId}/customers`,
      icon: UserGroupIcon,
      indentLevel: indentLevel,
    },
    ...customerDetail,
    {
      name: "塾生の属性",
      href: `/admin/shops/${correctedShopId}/customers-attributes`,
      current: path === `/admin/shops/${correctedShopId}/customers-attributes`,
      icon: InformationCircleIcon,
      indentLevel: indentLevel,
      // only: ["hq", ],
    },
    {
      name: "塾生のマッピング",
      href: `/admin/shops/${correctedShopId}/customer-mapping`,
      current: path === `/admin/shops/${correctedShopId}/customer-mapping`,
      icon: InformationCircleIcon,
      indentLevel: indentLevel,
      only: ["hq", "owner", "trainer"],
    },
  ];

  const others = [
    {
      name: "予約",
      href: `/admin/shops/${correctedShopId}/reserves`,
      current: path.startsWith(`/admin/shops/${correctedShopId}/reserves`),
      icon: CalendarDaysIcon,
      indentLevel: indentLevel,
    },
    {
      name: "塾生の支払い",
      href: `/admin/shops/${correctedShopId}/payments`,
      current: path.startsWith(`/admin/shops/${correctedShopId}/payments`),
      icon: CreditCardIcon,
      indentLevel: indentLevel,
    },
    {
      name: "売上明細",
      href: `/admin/shops/${correctedShopId}/sales/detail`,
      current: path.split("?")[0] === `/admin/shops/${correctedShopId}/sales/detail`,
      icon: CurrencyYenIcon,
      indentLevel: indentLevel,
    },
    {
      name: "売上グラフ",
      href: `/admin/shops/${correctedShopId}/sales`,
      current: path.split("?")[0] === `/admin/shops/${correctedShopId}/sales`,
      icon: ChartBarIcon,
      indentLevel: indentLevel,
    },
    {
      name: "体験予約",
      href: `/admin/shops/${correctedShopId}/trials`,
      current: path.startsWith(`/admin/shops/${correctedShopId}/trials`),
      icon: UserPlusIcon,
      indentLevel: indentLevel,
    },
    {
      name: "プラン契約",
      href: `/admin/shops/${correctedShopId}/plans`,
      current: path.startsWith(`/admin/shops/${correctedShopId}/plans`),
      icon: ArrowPathIcon,
      indentLevel: indentLevel,
    },
    {
      name: "累積プラン情報",
      href: `/admin/shops/${correctedShopId}/trainers-incentive`,
      current: path.startsWith(`/admin/shops/${correctedShopId}/trainers-incentive`),
      icon: UserMinusIcon,
      indentLevel: indentLevel,
      // only: ["hq"],
    },
    {
      name: "日報",
      href: `/admin/shops/${correctedShopId}/daily-reports`,
      current: path.startsWith(`/admin/shops/${correctedShopId}/daily-reports`),
      icon: DocumentCheckIcon,
      indentLevel: indentLevel,
    },
    {
      name: "食事サポート",
      href: `/admin/shops/${correctedShopId}/food-supports`,
      current: path.startsWith(`/admin/shops/${correctedShopId}/food-supports`),
      icon: ChatBubbleLeftRightIcon,
      indentLevel: indentLevel,
    },
    {
      name: "トレーナー",
      href: `/admin/shops/${correctedShopId}/trainers`,
      current: path.split("?")[0] === `/admin/shops/${correctedShopId}/trainers`,
      icon: UsersIcon,
      indentLevel: indentLevel,
    },
    {
      name: "担当トレーナー",
      href: `/admin/shops/${correctedShopId}/customers-trainers`,
      current: path.startsWith(`/admin/shops/${correctedShopId}/customers-trainers`),
      icon: UserGroupIcon,
      indentLevel: indentLevel,
    },
    {
      name: "退会フォーム",
      href: `/admin/shops/${correctedShopId}/withdrawns`,
      current: path.startsWith(`/admin/shops/${correctedShopId}/withdrawns`),
      icon: UserMinusIcon,
      indentLevel: indentLevel,
    },
    {
      name: "店舗情報",
      href: `/admin/shops/${correctedShopId}/settings`,
      current: path.startsWith(`/admin/shops/${correctedShopId}/settings`),
      icon: InformationCircleIcon,
      indentLevel: indentLevel,
    },
    {
      name: "退会予定の塾生",
      href: `/admin/shops/${correctedShopId}/left-customers`,
      current: path.startsWith(`/admin/shops/${correctedShopId}/left-customers`),
      icon: UserGroupIcon,
      indentLevel: indentLevel,
    },
    {
      name: "友達紹介",
      href: `/admin/shops/${correctedShopId}/customer-invitations`,
      current: path.startsWith(`/admin/shops/${correctedShopId}/customer-invitations`),
      icon: UserGroupIcon,
      indentLevel: indentLevel,
      only: ["hq"],
    },
    {
      name: "Shopify注文履歴",
      href: `/admin/shops/${correctedShopId}/shopify-orders`,
      current: path.startsWith(`/admin/shops/${correctedShopId}/shopify-orders`),
      icon: DocumentCheckIcon,
      indentLevel: indentLevel,
      only: ["hq", "owner", "trainer"],
    },
  ];

  return [...homeAndCustomer, ...others];
}
