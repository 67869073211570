import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useContext } from "react";

import { XMarkIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import Image from "next/image";
import { NavigationGroup } from "@/types/type";
import { AdminGlobalVarContext } from "@/providers/data/Common/Admin/GlobalVarProvider";
import { twMerge } from "tailwind-merge";

export default function SidebarSp(props: {
  sidebarOpen: boolean;
  setSidebarOpen: (value: boolean) => void;
  navigationGroup: NavigationGroup;
}) {
  const { sidebarOpen, setSidebarOpen, navigationGroup } = props;
  const { user } = useContext(AdminGlobalVarContext);
  const role = user.role;

  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 flex">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-cyan-700 pb-4 pt-5">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute right-0 top-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex flex-shrink-0 items-center px-4">
                <Image
                  src="/assets/svg/common/ktg-logo.svg"
                  alt="かたぎり塾"
                  width={100}
                  height={100}
                  className="h-8 w-8"
                />
              </div>
              <nav
                className="mx-2 mt-1 h-full flex-shrink-0 divide-y divide-white/30 overflow-y-auto"
                aria-label="Sidebar"
              >
                {Object.entries(navigationGroup).map(([key, navigationList]) => (
                  <div key={key} className="-px-2 mt-4 space-y-1 pt-4 first:pt-0">
                    {navigationList?.map((navigationItem) =>
                      !navigationItem.only || navigationItem.only?.includes(role) ? (
                        <Link
                          key={navigationItem.name}
                          href={navigationItem.href}
                          className={twMerge(
                            navigationItem.current
                              ? "bg-cyan-800 text-white"
                              : "text-cyan-100 hover:bg-cyan-600 hover:text-white",
                            "group flex items-center rounded-md py-2 pr-2 text-base font-medium",
                            navigationItem.indentLevel ? `pl-${navigationItem.indentLevel * 2 + 2}` : "pl-2"
                          )}
                          aria-current={navigationItem.current ? "page" : undefined}
                          onClick={() => setSidebarOpen(false)}
                        >
                          <navigationItem.icon
                            className="mr-4 h-6 w-6 flex-shrink-0 text-cyan-200"
                            aria-hidden="true"
                          />
                          {navigationItem.name}
                        </Link>
                      ) : (
                        <></>
                      )
                    )}
                  </div>
                ))}
              </nav>
            </Dialog.Panel>
          </Transition.Child>
          <div className="w-14 flex-shrink-0" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
