import SpinnerLarge from "@/components/General/Spinner/Large";
import { useRouter } from "next/router";
import { ReactNode } from "react";
import AdminHeader from "./Header";

import { useState } from "react";

import SidebarPc from "./SidebarPc";
import { Navigation, NavigationGroup } from "@/types/type";
import usePrimarySidebar from "@/hooks/General/SideBar/usePrimarySidebar";
import useSecondarySidebar from "@/hooks/General/SideBar/useSecondarySidebar";
import SidebarSp from "./SidebarSp";
import useStatisticsSidebar from "@/hooks/General/SideBar/useStaticsSidebar";
import useTestSidebar from "@/hooks/General/SideBar/useTestSidebar";

export default function AdminLayout(props: { children: ReactNode }) {
  const { children } = props;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const router = useRouter();

  const primaryNavigation: Navigation[] = usePrimarySidebar();
  const shopStatisticsNavigation = useStatisticsSidebar();
  const secondaryNavigation: Navigation[] | undefined = useSecondarySidebar();
  const testNavigation: Navigation[] = useTestSidebar();

  // undefinedを除外
  const navigationGroup: NavigationGroup = Object.fromEntries(
    Object.entries({
      primary: primaryNavigation,
      shopStatics: shopStatisticsNavigation,
      secondary: secondaryNavigation,
      test: testNavigation,
    }).filter(([, navigationList]) => navigationList?.length)
  );

  return router.isReady ? (
    <>
      <SidebarSp sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} navigationGroup={navigationGroup} />
      <div className="min-h-full">
        <SidebarPc navigationGroup={navigationGroup} />
        <div className="flex h-screen flex-1 flex-col lg:pl-64">
          <AdminHeader setSidebarOpen={setSidebarOpen} />
          <main className="relative grow overflow-y-scroll pb-8">{children}</main>
        </div>
      </div>
    </>
  ) : (
    <SpinnerLarge />
  );
}
