import { useContext, useRef, useState } from "react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import { AdminGlobalVarContext } from "@/providers/data/Common/Admin/GlobalVarProvider";
import { useRouter } from "next/router";
import { twMerge } from "tailwind-merge";

export default function AdminGlobalSearchInput() {
  const router = useRouter();

  const [selectedItem, setSelectedItem] = useState<{ id: string; name: string } | null>(null);

  const {
    shops: unfilteredShops,
    customers: unfilteredCustomers,
    filter,
    setFilter,
  } = useContext(AdminGlobalVarContext);

  const shops = unfilteredShops.map((shop) => ({
    ...shop,
    name: `${shop.id.toString().padStart(3, "0")} - ${shop.name}`,
  }));

  const customers = unfilteredCustomers.map((customer) => ({
    ...customer,
    name: `${customer.fullName} - ${customer.email}`,
  }));

  const filteredShops =
    shops.length === 1
      ? []
      : filter === ""
      ? shops
      : shops.filter((shop) => {
          return shop.name.toLowerCase().includes(filter.toLowerCase());
        });

  const filteredCustomers =
    filter === ""
      ? customers
      : customers.filter((customer) => {
          return customer.name.toLowerCase().includes(filter.toLowerCase());
        });

  const filteredItems = [
    ...(filteredShops.length > 0
      ? filteredShops.map((shop) => ({
          id: `shops/${shop.id}`,
          name: shop.name,
        }))
      : []),
    ...filteredCustomers.map((customer) => ({
      id: `customers/${customer.id}`,
      name: customer.name,
    })),
  ].slice(0, 200);

  const [itemChanged, setItemChanged] = useState(false);
  function onChangeItem(item: { id: string; name: string } | null) {
    setItemChanged(true);
    setSelectedItem(item);
    if (item) {
      router.push(`/admin/${item.id}`);
    }
  }
  const comboBtn = useRef<HTMLButtonElement>(null);
  const comboInput = useRef<HTMLInputElement>(null);
  const handleInputFocus = () => {
    if (itemChanged) {
      setItemChanged(false);
      comboInput.current?.blur();
      return;
    }
    comboBtn.current?.click();
  };

  return (
    <Combobox
      as="div"
      className={"absolute z-10 max-h-60 w-full py-2 pl-8 pr-3"}
      value={selectedItem}
      onChange={onChangeItem}
    >
      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900"></Combobox.Label>
      <div className="relative mt-2">
        <Combobox.Input
          className="block h-full w-full border-transparent text-gray-900 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
          onChange={(event) => setFilter && setFilter(event.target.value)}
          displayValue={(item?: { name: string | null }) => item?.name ?? ""}
          placeholder={
            unfilteredShops.length > 1 ? "店名・塾生名・メールアドレスで検索" : "塾生名・メールアドレスで検索"
          }
          value={filter}
          onFocus={handleInputFocus}
          ref={comboInput}
        />
        <Combobox.Button className="hidden" ref={comboBtn}></Combobox.Button>
        <Combobox.Options
          className="overflow-auto overflow-y-scroll rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          style={{ maxHeight: "90vh" }}
        >
          {filteredItems.map((item) => (
            <Combobox.Option
              key={item.id}
              value={item}
              className={({ active }) =>
                twMerge(
                  "relative cursor-pointer select-none py-2 pl-3 pr-9",
                  active ? "bg-cyan-600 text-white" : "text-gray-900",
                  selectedItem?.id === item.id ? "cursor-auto bg-cyan-50 text-cyan-800" : ""
                )
              }
            >
              {({ active, selected }) => (
                <>
                  <span className={`block truncate ${selected && "font-semibold"}`}>{item.name}</span>
                  {selected && (
                    <span
                      className={twMerge(
                        "absolute inset-y-0 right-0 flex items-center pr-4",
                        active ? "text-white" : "text-cyan-600"
                      )}
                    >
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  )}
                </>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </div>
    </Combobox>
  );
}
