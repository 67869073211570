import { User, useAuth0 } from "@auth0/auth0-react";

export type KtgUser = User & { "https://hasura.io/jwt/claims": { role: string; trainerId: number } };

/**
 * ```js
 * const {
 *   // Auth state:
 *   error,
 *   isAuthenticated,
 *   isLoading,
 *   user,
 *   // Auth methods:
 *   getAccessTokenSilently,
 *   getAccessTokenWithPopup,
 *   getIdTokenClaims,
 *   loginWithRedirect,
 *   loginWithPopup,
 *   logout,
 * } = useAuth0<KtgUser>();
 * ```
 *
 * Use the `useAuth0` hook in your components to access the auth state and methods.
 *
 * TUser is an optional type param to provide a type to the `user` field.
 * extend KtgUser to add more fields to the user object.
 */
const useKtgAuth0 = () => {
  return useAuth0<KtgUser>();
};

export default useKtgAuth0;
