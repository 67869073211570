import { useRouter } from "next/router";

export default function SpinnerLarge() {
  const router = useRouter();
  const isAdmin = router.pathname.startsWith("/admin");

  return (
    <div className="flex h-screen items-center justify-center">
      <div
        className={`h-32 w-32 animate-spin rounded-full border-b-2 ${isAdmin ? "border-cyan-500" : "border-primary"}`}
      ></div>
    </div>
  );
}
