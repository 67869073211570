import "@/styles/globals.css";
import "@/styles/body-graph.css";
import "@/styles/content-editor.css";

import type { AppProps } from "next/app";
import { Analytics } from "@vercel/analytics/react";
import { Auth0Provider } from "@auth0/auth0-react";
import Layout from "../components/Layout/index";
import { RecoilRoot } from "recoil";
import CustomApolloProvider from "@/providers/data/Common/ApolloProvider";
import Script from "next/script";

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Script strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=G-LGWVBSB51N`} />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-LGWVBSB51N');
            `,
        }}
      />
      <Auth0Provider
        domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN ?? ""}
        clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID ?? ""}
        authorizationParams={{
          redirect_uri: process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI ?? "",
          audience: "https://hasura.io/learn",
          scope: "openid profile email offline_access",
        }}
      >
        <CustomApolloProvider>
          <RecoilRoot>
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </RecoilRoot>
        </CustomApolloProvider>
      </Auth0Provider>
      <Analytics />
    </>
  );
}
