import { Navigation } from "@/types/type";
import { ChartPieIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { useRouter } from "next/router";

export default function useStatisticsSidebar(): Navigation[] | undefined {
  const router = useRouter();
  const path = router.asPath;

  return [
    {
      name: "店舗の統計情報",
      href: "/admin/shop-statistics",
      current: path.startsWith("/admin/shop-statistics"),
      icon: ChartPieIcon,
      only: ["hq", "owner"],
    },
    {
      name: "売上の統計情報",
      href: "/admin/sales-statistics",
      current: path.startsWith("/admin/sales-statistics"),
      icon: ChartPieIcon,
      only: ["hq", "owner"],
    },
    {
      name: "体験顧客属性",
      href: "/admin/customer-attributes",
      current: path.startsWith("/admin/customer-attributes"),
      icon: ChartPieIcon,
      only: ["hq"],
    },
    {
      name: "既存顧客属性",
      href: "/admin/existing-customer-attributes",
      current: path.startsWith("/admin/existing-customer-attributes"),
      icon: ChartPieIcon,
      only: ["hq", "owner", "trainer"],
    },
    {
      name: "退会予定の塾生",
      href: "/admin/left-customers",
      current: path.startsWith("/admin/left-customers"),
      icon: UserGroupIcon,
      only: ["hq"],
    },
  ];
}
