import SpinnerLarge from "@/components/General/Spinner/Large";
import {
  GetAdminGlobalVarDataDocument,
  GetAdminGlobalVarDataQuery,
  GetCustomersLiteDocument,
  GetCustomersLiteQuery,
} from "@/graphql/generated/graphql";
import useCanameCoreV1 from "@/hooks/CanameCore/v1/useCanameCoreV1";
import { useGetRole } from "@/hooks/Common/useGetRole";
import useShowAlert from "@/hooks/useHandleError";
import { dayjsTz } from "@/lib/dayjs";
import { WithdrawnFormPostResource } from "@/types/type";
import { useQuery } from "@apollo/client";
import { Dayjs } from "dayjs";
import { useState } from "react";
import { createContext } from "react";

class AdminGlobalVar {
  user: {
    role?: "hq" | "owner" | "trainer" | "customer";
  } = {};
  shops: GetAdminGlobalVarDataQuery["shops"] = [];
  refetchShops?: () => void;
  shopsLoading = false;
  customers: {
    id: number;
    shopId?: number;
    fullName: string;
    email: string;
  }[] = [];
  refetchCustomers?: () => void;
  customersLoading = false;
  customersWithdrawnPosts: WithdrawnFormPostResource[] = [];
  refetchWithdrawnFormPosts: () => void = () => void 0;
  customersWithdrawnMap: {
    [email: string]: {
      inputDate: Dayjs;
      continuousMonths: number;
      reason: string;
      memory: string;
      comment: string;
      goodPoints: string;
      badPoints: string;
    };
  } = {};
  filter = "";
  setFilter?: (filter: string) => void;

  getMonthlyData: (monthKey: string, shopId: number) => { sales: number | null } = () => {
    return { sales: null };
  };
  setMonthlyData: (monthKey: string, shopId: number, sales: number) => void = () => void 0;
}

export type AdminGlobalVarType = typeof AdminGlobalVar;

export const AdminGlobalVarContext = createContext<AdminGlobalVar>(new AdminGlobalVar());

export default function AdminGlobalVarProvider(props: { children: React.ReactNode }) {
  const children = props.children;

  const [filter, setFilter] = useState("");

  const [monthlyData, setMonthlyData] = useState<{
    [monthKey: string]: {
      [shopId: string]: {
        sales: number;
      };
    };
  }>({});

  const { onError } = useShowAlert();

  const {
    data: shopsData,
    loading: shopsLoading,
    refetch: refetchShops,
  } = useQuery<GetAdminGlobalVarDataQuery>(GetAdminGlobalVarDataDocument, {
    onError,
  });

  const {
    data: customersData,
    loading: customersLoading,
    refetch: refetchCustomers,
  } = useQuery<GetCustomersLiteQuery>(GetCustomersLiteDocument, {
    onError,
  });

  const role = useGetRole();

  const withdrawnFormPostsUrl = "/resources/withdrawn-form-posts";
  const { data: _withdrawnData, mutate: mutateWithdrawnFormPosts } = useCanameCoreV1<WithdrawnFormPostResource[]>(
    "GET",
    withdrawnFormPostsUrl
  );

  const withdrawnFormPosts = _withdrawnData ?? [];

  const adminGlobalVar: AdminGlobalVar = {
    user: {
      role: role,
    },
    shops: shopsData?.shops ?? [],
    refetchShops,
    customers:
      customersData?.customers?.map((customer) => {
        return {
          id: customer.id,
          shopId: customer.shopId,
          fullName: customer.fullName,
          email: customer.email,
        };
      }) ?? [],
    refetchCustomers,
    filter,
    setFilter,
    shopsLoading,
    customersLoading,
    customersWithdrawnPosts: withdrawnFormPosts,
    refetchWithdrawnFormPosts: () => {
      mutateWithdrawnFormPosts(withdrawnFormPostsUrl);
    },
    customersWithdrawnMap: withdrawnFormPosts.reduce(
      (acc, withdrawnFormPost) => {
        const email = withdrawnFormPost.email;
        acc[email] = {
          inputDate: dayjsTz(withdrawnFormPost.posted_at),
          continuousMonths: withdrawnFormPost.membership_period,
          reason: withdrawnFormPost.reason,
          memory: withdrawnFormPost.memory,
          comment: withdrawnFormPost.comment,
          goodPoints: withdrawnFormPost.good_points,
          badPoints: withdrawnFormPost.bad_points,
        };
        return acc;
      },
      {} as {
        [email: string]: {
          inputDate: Dayjs;
          continuousMonths: number;
          reason: string;
          memory: string;
          comment: string;
          goodPoints: string;
          badPoints: string;
        };
      }
    ),
    getMonthlyData: (monthKey: string, shopId: number) => {
      if (monthlyData[monthKey] && monthlyData[monthKey][shopId]) {
        return monthlyData[monthKey][shopId];
      }
      return { sales: null };
    },
    setMonthlyData: (monthKey: string, shopId: number, sales: number) => {
      if (!monthlyData[monthKey]) {
        monthlyData[monthKey] = {};
      }
      monthlyData[monthKey][shopId] = { sales };
      setMonthlyData({ ...monthlyData });
    },
  };

  return (
    <AdminGlobalVarContext.Provider value={adminGlobalVar}>
      {shopsLoading || customersLoading ? <SpinnerLarge /> : children}
    </AdminGlobalVarContext.Provider>
  );
}
