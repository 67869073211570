import Image from "next/image";
import Link from "next/link";
import { NavigationGroup } from "@/types/type";
import { twMerge } from "tailwind-merge";
import { useContext } from "react";
import { AdminGlobalVarContext } from "@/providers/data/Common/Admin/GlobalVarProvider";

export default function SidebarPc({ navigationGroup }: { navigationGroup: NavigationGroup }) {
  const { user } = useContext(AdminGlobalVarContext);
  const role = user.role;

  return (
    <>
      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-grow flex-col overflow-y-auto bg-cyan-700 pb-4 pt-5">
          <div className="flex flex-shrink-0 items-center px-4">
            <Image
              src="/assets/svg/common/ktg-logo.svg"
              alt="かたぎり塾"
              width={100}
              height={100}
              className="h-8 w-8 rounded-full"
            />
          </div>
          <nav className="mx-4 flex flex-1 flex-col divide-y divide-white/30 overflow-y-auto" aria-label="Sidebar">
            {Object.entries(navigationGroup).map(([key, navigationList]) => (
              <div key={key} className="-px-2 mt-4 space-y-1 pt-4 first:pt-0">
                {navigationList?.map((navigationItem) =>
                  // ロールによる出しわけ
                  !navigationItem.only || navigationItem.only?.includes(role) ? (
                    <Link
                      key={navigationItem.name}
                      href={navigationItem.href}
                      className={twMerge(
                        navigationItem.current
                          ? "bg-cyan-800 text-white"
                          : "text-cyan-100 hover:bg-cyan-600 hover:text-white",
                        "group flex items-center rounded-md py-2 pr-2 text-sm font-medium leading-6"
                      )}
                      style={{ paddingLeft: `${((navigationItem.indentLevel || 0) * 4 + 2) * 4}px` }} // tailwindのarbitrary valueは動的に動作しない
                      aria-current={navigationItem.current ? "page" : undefined}
                    >
                      <navigationItem.icon className="mr-4 h-6 w-6 flex-shrink-0 text-cyan-200" aria-hidden="true" />
                      {navigationItem.name}
                    </Link>
                  ) : (
                    <></>
                  )
                )}
              </div>
            ))}
          </nav>
        </div>
      </div>
    </>
  );
}
