import useKtgAuth0 from "@/hooks/auth/useKtgAuth0";
import useShowAlert from "@/hooks/useHandleError";
import { Arguments, Fetcher, MutatorOptions, useSWRConfig } from "swr";
import useSWRImmutable from "swr/immutable";

export default function useCanameCoreV1<Data = unknown>(
  httpMethod: "GET" | "POST" | "PUT" | "DELETE" | "PATCH",
  path: string,
  handlers?: {
    onSuccess: (data: Data) => void;
    onError?: (err: Error) => "showAlert" | "hideAlert";
  }
) {
  const { getAccessTokenSilently } = useKtgAuth0();

  const apiUrl = `${process.env.NEXT_PUBLIC_CANAME_CORE_V1_ENDPOINT}${path}`;

  const fetcher: Fetcher<{
    data: Data;
  }> = async () => {
    const res = await fetch(apiUrl, {
      method: httpMethod,
      headers: {
        Authorization: `Bearer ${await getAccessTokenSilently({
          authorizationParams: {
            audience: "https://hasura.io/learn",
            scope: "openid profile email offline_access",
          },
        })}`,
      },
    });
    try {
      const json = await res.json();
      return json;
    } catch (err) {
      return null;
    }
  };

  const { onError } = useShowAlert();

  const { mutate } = useSWRConfig();
  const { data, isLoading, error } = useSWRImmutable(path, fetcher, {
    onError: (err) => {
      const needsShowAlert = handlers?.onError?.(err) !== "hideAlert";
      if (needsShowAlert) {
        onError(err);
      }
    },
    onSuccess: (data) => {
      handlers?.onSuccess(data.data);
    },
  });

  return {
    data: data?.data,
    error,
    isLoading,
    mutate: (key?: Arguments, data?: unknown, opts?: boolean | MutatorOptions<unknown, unknown> | undefined) => {
      console.log("mutate", key ?? path, key, data, opts);
      mutate(key ?? path, data, opts);
    },
  };
}
