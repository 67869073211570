import dayjs from "dayjs";
import "dayjs/locale/ja";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

export function dayjsTz(date?: dayjs.ConfigType, format?: dayjs.OptionType, locale?: string, strict?: boolean) {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault("Asia/Tokyo");
  dayjs.locale("ja");
  return dayjs(date, format, locale, strict).tz();
}
