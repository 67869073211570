import { ModalType, ModalOpenState } from "@/states/modal";
import { useRecoilState, SetterOrUpdater, useSetRecoilState } from "recoil";

type Response = [boolean, SetterOrUpdater<boolean>];

export default function useModal(modalType: ModalType): Response {
  const [modal, setModal] = useRecoilState(ModalOpenState(modalType));

  return [modal, setModal];
}

export function useSetModal(modalType: ModalType): SetterOrUpdater<boolean> {
  return useSetRecoilState(ModalOpenState(modalType));
}
